import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { swapTeamIdsForAllPlayers, swapPlayersBetweenTeams } from 'redux/slices';

export function SwapTeamBtn() {
  const dispatch = useDispatch();

  const handleSwap = () => {
    dispatch(swapTeamIdsForAllPlayers());
    dispatch(swapPlayersBetweenTeams());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
      }}
    >
      <Button onClick={handleSwap} variant="contained" size="small">Trocar lado dos times</Button>
    </Box>
  );
}
