import { useState, useEffect } from 'react';
import {
  Typography, Paper, Container, Box, Button, TextField,
} from '@mui/material';

export function Draft() {
  const [score, setScore] = useState([0, 0]);
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [gameStarted, setGameStarted] = useState(false);
  const [players, setPlayers] = useState([]);
  const [newPlayerName, setNewPlayerName] = useState('');

  const handleScore = (index) => {
    const newScore = [...score];
    newScore[index] += 1;
    setScore(newScore);
  };

  const cancelScore = (index) => {
    const newScore = [...score];
    newScore[index] -= 1;
    setScore(newScore);
  };

  const addPlayer = () => {
    const newPlayer = {
      name: newPlayerName,
      waitingFor: 0,
      isPlaying: false,
    };
    setPlayers((prevPlayers) => [...prevPlayers, newPlayer]);
    setNewPlayerName(''); // reset the input field
  };

  const startGame = () => {
    setGameStarted(true);
  };

  const togglePlayerStatus = (playerName) => {
    setPlayers((prevPlayers) => prevPlayers.map((player) => {
      if (player.name === playerName) {
        return {
          ...player,
          isPlaying: !player.isPlaying,
          waitingFor: player.isPlaying ? player.waitingFor : 0,
        };
      }
      return player;
    }));
  };

  const resetGame = () => {
    setGameStarted(false);
    setTime({ minutes: 0, seconds: 0 });
    setScore([0, 0]);

    // Modify player status on game reset
    const updatedPlayers = players.map((player) => {
      if (!player.isPlaying) {
        return {
          ...player,
          waitingFor: player.waitingFor + 1,
        };
      }
      return {
        ...player,
        isPlaying: false,
      };
    });
    setPlayers(updatedPlayers);
  };

  useEffect(() => {
    let timer;
    if (gameStarted) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime.seconds < 59) {
            return { ...prevTime, seconds: prevTime.seconds + 1 };
          }
          return { minutes: prevTime.minutes + 1, seconds: 0 };
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [gameStarted]);

  return (
    <Container>
      <Typography variant="h3" component="h2" textAlign="center">
        Volei do Blend
      </Typography>
      <Box>
        <Paper elevation={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Box mb={3}>
            <Box>
              <Typography variant="h1" component="h1" textAlign="center">
                {score[0]}
              </Typography>
            </Box>
            <Box>
              <Button variant="contained" onClick={() => handleScore(0)}>+</Button>
              <Button variant="contained" onClick={() => cancelScore(0)}>-</Button>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="h1" component="h1" textAlign="center">
              X
            </Typography>
          </Box>
          <Box>
            <Box>
              <Typography variant="h1" component="h1" textAlign="center">
                {score[1]}
              </Typography>
            </Box>
            <Box>
              <Button variant="contained" onClick={() => handleScore(1)}>+</Button>
              <Button variant="contained" onClick={() => cancelScore(1)}>-</Button>
            </Box>
          </Box>
        </Paper>
        <Box mt={3}>
          {/* Player Input and Add button */}
          <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
            <TextField
              label="Player Name"
              value={newPlayerName}
              onChange={(e) => setNewPlayerName(e.target.value)}
            />
            <Button variant="contained" onClick={addPlayer}>ADD</Button>
          </Box>
          {/* Time display and game control buttons */}
          <Typography variant="h4" textAlign="center" mt={2}>
            {`${time.minutes}m:${time.seconds < 10 ? '0' : ''}${time.seconds}s`}
          </Typography>
          <Box mt={2} textAlign="center">
            {
              !gameStarted
                ? <Button variant="contained" onClick={startGame}>Começar Jogo</Button>
                : <Button variant="contained" color="secondary" onClick={resetGame}>Finalizar</Button>
            }
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="h5" textAlign="center">
            Jogadores e Tempo de Espera:
          </Typography>
          <Box mt={2}>
            {players.filter((p) => !p.isPlaying)
              .sort((a, b) => b.waitingFor - a.waitingFor).map((player) => (
                <Box key={player.name} display="flex" justifyContent="center" alignItems="center" gap={2} mb={1}>
                  <Typography variant="h6">{player.name}</Typography>
                  <Typography variant="h6">
                    (
                    {player.waitingFor}
                    )
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => togglePlayerStatus(player.name)}
                  >
                    Jogar
                  </Button>
                </Box>
              ))}
          </Box>
          <Box mt={4}>
            <Typography variant="h5" textAlign="center">
              Jogando agora:
            </Typography>
            <Box mt={2}>
              {players.filter((p) => p.isPlaying).map((player) => (
                <Box key={player.name} display="flex" justifyContent="center" alignItems="center" gap={2} mb={1}>
                  <Typography variant="h6">{player.name}</Typography>
                  <Button
                    variant="contained"
                    onClick={() => togglePlayerStatus(player.name)}
                  >
                    Remover Jogador
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
