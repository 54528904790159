/* eslint-disable max-len */
import { Box, Typography, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import OfflinePlayerCard from './OfflinePlayerCard';

export function PlayersOffline({ ranking, isAdmin }) {
  const players = useSelector((state) => state.players);
  const [filter, setFilter] = useState('');

  const playersOffline = players.filter((p) => !p.checkedIn);

  if (ranking || isAdmin) {
    playersOffline.sort((a, b) => b.wins - b.losses - (a.wins - a.losses));
  }

  // Function to handle the change in the search input
  const handleSearchChange = (event) => {
    setFilter(event.target.value);
  };

  // Filter players based on the search input
  const filteredPlayers = playersOffline.filter((player) => player.playerName.toLowerCase().includes(filter.toLowerCase()));

  return (
    <Box
      sx={{
        mt: 4,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" textAlign="center">
        Jogadores fora da quadra
      </Typography>

      {/* Search Input */}
      <TextField
        label="Filtrar por nome"
        variant="outlined"
        value={filter}
        onChange={handleSearchChange}
        sx={{ mt: 2, mb: 2, width: '80%' }}
        fullWidth
      />

      <Box
        sx={{
          mt: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {filteredPlayers.length > 0 ? (
          filteredPlayers.map((player, index) => (
            <OfflinePlayerCard player={player} position={index + 1} ranking={ranking} isAdmin={isAdmin} />
          ))
        ) : (
          'Nenhum jogador encontrado'
        )}
      </Box>
    </Box>
  );
}
