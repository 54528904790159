import React from 'react';
import { useDispatch } from 'react-redux';
import { checkOut } from 'redux/slices';
import { ConfirmationModal } from './ConfirmationModal';

export function RemovePlayer({ playerId, isPlaying = false }) {
  const dispatch = useDispatch();

  const handleRemovePlayer = () => {
    dispatch(checkOut(playerId));
    if (isPlaying !== false) isPlaying();
  };

  return (
    <ConfirmationModal
      title="Fazer checkout de jogador"
      description="Tem certeza que deseja fazer checkout deste jogador?"
      handleConfirm={handleRemovePlayer}
      handleCancel={() => { }}
      triggerLabel="Checkout"
    />
  );
}
