import React from 'react';
import { useDispatch } from 'react-redux';
import { deletePlayer } from 'redux/slices';
import { ConfirmationModal } from './ConfirmationModal';

export function DeletePlayer({ playerId }) {
  const dispatch = useDispatch();

  const handleDeletePlayer = () => {
    dispatch(deletePlayer(playerId));
  };

  return (
    <ConfirmationModal
      title="Deletar jogador!"
      description="Tem certeza que deseja fazer deletar este jogador?"
      handleConfirm={handleDeletePlayer}
      handleCancel={() => { }}
      triggerLabel="Deletar jogador"
    />
  );
}
