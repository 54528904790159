import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  saveTeamOnTeamC, retrieveTeamC, swapTeamIdToId3, swapTeamId3ToTeamId,
} from 'redux/slices';

export function SaveTeamForNextMatchBtn({ teamId, canSave }) {
  const dispatch = useDispatch();
  const players = useSelector((state) => state.players);
  const teamCIsEmpty = players.filter((p) => p.teamId === 3).length === 0;

  const handleSaveTeamForNextMatch = () => {
    dispatch(saveTeamOnTeamC({ teamId }));
    dispatch(swapTeamIdToId3({ teamId }));
  };

  const handleRetrievalTeamForNextMatch = () => {
    dispatch(retrieveTeamC({ teamId }));
    dispatch(swapTeamId3ToTeamId({ teamId }));
  };

  return (
    canSave
      ? teamCIsEmpty && <Button onClick={handleSaveTeamForNextMatch}>Salvar time</Button>
      : !teamCIsEmpty
        && <Button onClick={handleRetrievalTeamForNextMatch}>Recuperar time salvo</Button>
  );
}
