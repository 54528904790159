import { useState } from 'react';

export function UpdateStore() {
  const [inputData, setInputData] = useState('');
  const storedData = localStorage.getItem('reduxStore');

  const handleInputChange = (event) => {
    setInputData(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setInputData(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  const updateStore = () => {
    try {
      const jsonData = JSON.parse(inputData);
      localStorage.setItem('reduxStore', JSON.stringify(jsonData));
    } catch (error) {
      alert('Invalid JSON data');
    }
  };

  return (
    <div>
      <textarea
        placeholder="Paste JSON data here"
        value={inputData}
        onChange={handleInputChange}
      />
      <br />
      <input type="file" onChange={handleFileChange} />
      <br />
      <button type="button" onClick={updateStore}>Update Store</button>
      <br />
      <br />
      <p>Export data:</p>
      <textarea
        value={storedData}
      />
    </div>
  );
}
