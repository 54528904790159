import { configureStore } from '@reduxjs/toolkit';
import storeSynchronize from 'redux-localstore';
import {
  teamsSlice, playersSlice, gameSlice, youtubeSlice,
} from './slices';

export const store = configureStore({
  reducer: {
    teams: teamsSlice.reducer,
    players: playersSlice.reducer,
    game: gameSlice.reducer,
    youtube: youtubeSlice.reducer,
  },
});

storeSynchronize(store, {
  whitelist: ['teams', 'players', 'game'],
  blacklist: [],
});
