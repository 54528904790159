import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import { useState } from 'react';

export function ConfirmationModal({
  triggerLabel,
  handleConfirm,
  handleCancel,
  title,
  description,
  triggerConfig,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="outlined" {...triggerConfig} onClick={() => setOpen(true)}>{triggerLabel}</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
              handleCancel();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              handleConfirm();
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
