import React from 'react';
import { checkOutAllPlayers } from 'redux/slices';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from './ConfirmationModal';

export function CheckoutAllPlayers() {
  const dispatch = useDispatch();
  const handleCheckoutAllPlayers = () => {
    dispatch(checkOutAllPlayers());
  };
  return (
    <ConfirmationModal
      title="Deseja remover todos os jogadores da quadra?"
      content="Essa ação não pode ser desfeita."
      triggerLabel="Fazer checkout de todos os jogadores"
      handleConfirm={handleCheckoutAllPlayers}
      onCancel={() => { }}
      triggerConfig={{
        variant: 'contained',
        color: 'error',
        sx: {
          width: '100%',
        },
      }}
    />
  );
}
