import { createSlice } from '@reduxjs/toolkit';

const initialState = { currentSong: '', nextSong: '', playList: [] };

export const youtubeSlice = createSlice({
  name: 'youtube',
  initialState,
  reducers: {
    setCurrentSong: (state, { payload }) => {
      state.currentSong = payload;
    },
  },
});

export const { setCurrentSong } = youtubeSlice.actions;
