import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { keyframes } from '@emotion/react';

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1440deg);
  }
`;

export const SpinningTypography = styled(Typography)`
  animation: ${spin} 4s linear;
`;
