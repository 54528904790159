import React from 'react';
import { useDispatch } from 'react-redux';
import { checkIn } from 'redux/slices';
import { ConfirmationModal } from './ConfirmationModal';

export function CheckInPlayer({ playerId }) {
  const dispatch = useDispatch();

  const handleCheckInPlayer = () => {
    dispatch(checkIn(playerId));
  };

  return (
    <ConfirmationModal
      title="Fazer check-in de jogador"
      description="Tem certeza que deseja fazer check-in deste jogador?"
      handleConfirm={handleCheckInPlayer}
      handleCancel={() => { }}
      triggerLabel="Check-in"
    />
  );
}
