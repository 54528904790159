import { addWin, addLoss } from 'redux/slices';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';

export function UpdatePlayerValue({ playerId }) {
  const dispatch = useDispatch();

  const handleWin = () => {
    dispatch(addWin(playerId));
  };

  const handleLoss = () => {
    dispatch(addLoss(playerId));
  };

  return (
    <div>
      <Button variant="contained" onClick={handleWin}>+</Button>
      <Button variant="contained" onClick={handleLoss}>-</Button>
    </div>
  );
}
