import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTime } from 'redux/slices';
import { Typography } from '@mui/material';

export function Counter() {
  const { gameStarted, time } = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const timeRef = useRef(time);

  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    let timer;
    if (gameStarted) {
      timer = setInterval(() => {
        const { seconds, minutes } = timeRef.current;
        if (seconds < 59) {
          dispatch(setTime({ ...timeRef.current, seconds: seconds + 1 }));
        } else {
          dispatch(setTime({ minutes: minutes + 1, seconds: 0 }));
        }
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [gameStarted, dispatch]);

  return (
    <Typography variant="h4" textAlign="center" mt={2}>
      {`${time.minutes}m:${time.seconds < 10 ? '0' : ''}${time.seconds}s`}
    </Typography>
  );
}
